import { camelToSnakeCase } from 'utils/formatting/camelSnakeCase';

// don't need location to trigger results update until we get bounding box
const ignoredKeys = [
  'location',
  'locationKey',
  'ssrKey',
  'shallowRouting',
  'city',
  'state',
];
const camelCaseKeys = ['bottomLat', 'leftLong', 'rightLong', 'topLat'];

export const PAGE_SIZE = process.env.REACT_APP_SEARCH_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_SEARCH_PAGE_SIZE, 10)
  : 36;

export function formatFilters(filters, allowAllKeys = false) {
  const result = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (!allowAllKeys && ignoredKeys.includes(key)) {
      return;
    }
    if (value !== null && value !== '') {
      if (camelCaseKeys.includes(key)) {
        result[key] = value;
      } else {
        result[camelToSnakeCase(key)] = value;
      }
    }
  });
  result.page_size = PAGE_SIZE;

  return result;
}

export default function formatSearchData(filters, data) {
  const input = data?.data?.hits?.hits[0]?._source;

  const [rightLong, bottomLat, leftLong, topLat] = input?.bbox ?? [
    null,
    null,
    null,
    null,
  ];

  const searchFilters = {
    location: input?.name?.replace(', United States', '') ?? '',
    locationKey: filters.searchterm,
    topLat,
    bottomLat,
    leftLong,
    rightLong,
    lat: input?.lat,
    long: input?.lng,
    city: input?.city,
    state: input?.state_code,
    sortOrder: { value: 'random', order: 'lh' },
    skip_map: 1,
    shallowRouting: false,
  };
  return { ...searchFilters, ...filters };
}
