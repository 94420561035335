import { PAGE_SIZE } from './search/formatSearchData';

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function calculateAds({
  googleFrequency,
  dwellsyFrequency,
  promoAds,
  pageSize = PAGE_SIZE,
}) {
  const ads = {
    google: [],
    dwellsy: {},
  };

  if (googleFrequency) {
    if (googleFrequency >= pageSize) {
      ads.google = Array.from(
        { length: Math.min(googleFrequency, pageSize) },
        (_, i) => i,
      );
    } else {
      const step = Math.floor(pageSize / googleFrequency);
      let x;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < googleFrequency; i++) {
        x = Math.min(
          randomIntFromInterval(1, step) + i * step - 1,
          pageSize - 1,
        );
        ads.google.push(x);
      }
    }
  }

  if (dwellsyFrequency?.length && promoAds?.length) {
    // sort PromoAds
    const sortedAds = promoAds
      .map((a) => a)
      .sort((a, b) => {
        const aPos =
          a?.position ??
          randomIntFromInterval(promoAds.length + 1, promoAds.length * 2);
        const bPos =
          b?.position ??
          randomIntFromInterval(promoAds.length + 1, promoAds.length * 2);

        return aPos - bPos;
      });

    let adIdx = 0;
    dwellsyFrequency.forEach((f) => {
      const pos = randomIntFromInterval(f.min, f.max);
      if (adIdx >= sortedAds.length) {
        adIdx = 0;
      }
      ads.dwellsy[pos] = sortedAds[adIdx];
      adIdx += 1;
    });
  }

  return ads;
}

export default calculateAds;
