export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelToCase = (str) =>
  str.replace(/_[a-z]/g, (chars) => chars.charAt(1).toUpperCase());

export default {
  camelToSnakeCase,
  snakeToCamelToCase,
};
